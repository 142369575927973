import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AppState, store } from "store";
import { TAXPAYER } from "route/paths";
import { setStatusUpdateModel } from "store/slices/statusUpdateSlice";
import { CustomModal } from "./UIComponents/CustomModal";
import { ButtonConstant, StatusUpdateConstants } from "helper/Constants";
import "./styles/AppLayout.scss"
import { NotificationType } from "common/enum";
import { getCurrentClient, isMobile } from "helper/HelperFunctions";
import { ClientModel, initialClientModel } from "common/model/clients";
import NoticePopup from "./UIComponents/NoticePopup";
import { HeaderInfoViewModel } from "common/model/headerInfo";

declare global {
    interface Window {
        Variables: any;
        pendo: any;
    }
  }

const AppLayout: React.FC<{ children: ReactElement }> = ({ children }) => {
	const navigate = useNavigate();
    const dispatch  = useDispatch();
    const isStatusChanged = useAppSelector((state: AppState) => state.statusUpdateReducer?.isStatusChanged); 
    const clientBasicInfo:HeaderInfoViewModel = useAppSelector((state: AppState) => state.coverPageReducer);

    useEffect(()=>{
        if(clientBasicInfo.companyId!==0){
            injectPendoScript()
        }
    },[clientBasicInfo])




    const injectPendoScript = () => {
        const user ={
          company_name:clientBasicInfo.companyName,
          company_id : clientBasicInfo.companyId,
          email: clientBasicInfo.clientEmailAddress,
          name:clientBasicInfo.clientName,
          isTestCompany: clientBasicInfo.isTestCompany,
          companySubscription: clientBasicInfo.companySubscription
        };

        if (!user.company_id || !user.company_name || !user.name || !user.email) {
          console.log("User profile not available yet");
          return;
        }

        const apiKey = process.env.REACT_APP_TP_PENDO_API_KEY;
    
        (function (p: any, e: any, n: any, d: any, o: any) {
          var v: any, w: any, x: any, y: any, z: any;
          o = p[d] = p[d] || {};
          o._q = o._q || [];
          v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                };
            })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo", "");
    
        window.pendo?.initialize({
          visitor: {
            id: `${user.company_id}-${user.email}`,
            email: user.email,
            name: user.name,
          },
          account: {
            Id: user.company_id,
            AccountName: user.company_name,
            FirmType: user.isTestCompany ? "Internal" : "Live",
            Subscription: user.companySubscription
          }
        });
      };

    const handleStatusUpdateModal = () => {   
        dispatch(setStatusUpdateModel({ isStatusChanged: false, notificationType: NotificationType.None })); 
        let currentClient: ClientModel = getCurrentClient(store.getState().clientReducer?.taxpayer, store.getState().clientReducer?.spouse);
        navigate(`${TAXPAYER.LANDING_PAGE}${currentClient.clientGuid}`);
        navigate(0);
    };
    

	return <>
		<CustomModal
                className='status-update-notification-modal'
                show={isStatusChanged}
                onHide={handleStatusUpdateModal}
                hideCancelButton = {true}
                title={StatusUpdateConstants.title}
                confirmButtonName={ButtonConstant.Refresh}
                onSubmit={handleStatusUpdateModal}
                backdropValue="static">  
                <div>
                    <p>{StatusUpdateConstants.message}</p>
                </div>                        
		</CustomModal>
        {isMobile() ? <NoticePopup/> : <></>}
		<div className={isMobile() ? "mobile-view" : ""}>{children}</div>
	</>;
};

export default AppLayout;