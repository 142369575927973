import { BrandingSettings, initialBrandingSettings } from "./brandSetting";
import { LoggedInUserInfo, initialLoggedInUserInfo } from "./loggedInUserInfo";
import { TaxDocumentModel, initialTaxDocumentModel } from "./taxDocument";
import { UserModel, initialUserModel } from "./user";

export type HeaderInfoViewModel =  {
	clientName: string,
	taxYear: number;
	companyName: string,
	companyLogoPath: string,
	companyWhiteLogoPath: string,
	contactPerson: UserModel,
	brandingSettings: BrandingSettings,
	data: TaxDocumentModel,
	loggedInUserInfo: LoggedInUserInfo,
	isPreview: boolean;
	isLoading: boolean;
	clientMobileNumber:string;
	companyId:number,
	clientEmailAddress:string,
	isTestCompany:boolean,
	companySubscription:string
}

export const initialHeaderInfoViewModel: HeaderInfoViewModel = {
	clientName: "",
	taxYear: 0,
	companyName: "",
	companyLogoPath: "",
	companyWhiteLogoPath: "",
	contactPerson:initialUserModel ,
	brandingSettings: initialBrandingSettings,
	data: initialTaxDocumentModel,
	loggedInUserInfo: initialLoggedInUserInfo,
	isPreview: false,
	isLoading: false,
	clientMobileNumber:"",
	companyId:0,
	clientEmailAddress:"",
	isTestCompany:true,
	companySubscription:""
}