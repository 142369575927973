import React, { FC, useEffect, useState } from "react";
import UserDropdown from "./UserDropdown";
import ContactPopup from "./ContactPopup";
import { ErrorStatus, HeaderType } from "../../enum";
import StepComponent from "./StepComponent";
import Drawer from "../UIComponents/Drawer";
import { ChatIconMobile, ClockCalenderBG, HamburgerIcon, LogOutIcon, MailIcon, UserIcon } from "../../../assets/svg/SVGIconsCollection";
import { handleRequestError, isMobile } from "../../../helper/HelperFunctions";
import CpaMessageModal from "./CpaMessageModal";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { requestHeaderInfo } from "store/services/coverPage";
import { useClientId } from "common/hooks/client-hook";
import { AppState } from "store";
import { getSpouseInfo, getTaxPayer } from "store/services/clients";
import { ClientModel } from "common/model/clients";
import { logOut } from "store/services/otpStore";
import { TAXPAYER } from "../../../route/paths";
import companyImage from 'assets/images/GtrLogo.png'
import "./header.scss";
import { getOneHubRedirectUrl, getUTESettings } from "store/services/companySettings";
import { IUteSettings } from "common/model/company-settings";
import { HomeIcon } from "assets/icons/IconCollection";

interface IHeaderProps {
    type: HeaderType;
    fromLinkLock?: boolean;
    hideContact?: boolean;

}

const Header: FC<IHeaderProps> = ({ type, fromLinkLock, hideContact }) => {
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [showMessagePopup, setShowMessagePopup] = useState<boolean>(false);
    const [uteSettings, setUteSettings] = useState<IUteSettings>({uteEnabled: false, uteModified: ''});
    const isAccessedFromOneHub = useAppSelector((state: AppState) => state.metaDataReducer?.gatherMetadata?.isAccessedFromOneHub);
    const navigate = useNavigate();
    const [clientId] = useClientId();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(requestHeaderInfo(clientId, false, () => {}, handleRequestError))
        dispatch(getTaxPayer(clientId))
        dispatch(getSpouseInfo(clientId))
        dispatch(getUTESettings(clientId, (data: IUteSettings) => setUteSettings(data)))
    }, [])

    const companyLogoPath = useAppSelector((state: AppState) => state.coverPageReducer?.companyLogoPath)

    const contactPerson = useAppSelector((state: AppState) => state.coverPageReducer?.contactPerson)

    const taxpayer: ClientModel = useAppSelector((state: AppState) => state.clientReducer?.taxpayer)

    const spouse: ClientModel = useAppSelector((state: AppState) => state.clientReducer?.spouse)
    
    const message = useAppSelector((state : AppState)=>state.preparerMessageReducer?.message);

    const handleLogout = () => {
        dispatch(logOut(clientId, logOutCallback))
        localStorage.removeItem('encodedClientId_'+clientId)
    };

    const logOutCallback = (encodedClientId: string) => {
        navigate("/" + encodedClientId)
    }

    const handleRequestError = (errorStatus: ErrorStatus) => {
        if(errorStatus === ErrorStatus.ClosedByFirm){
            handleClosedByFirm();
            return;
        }
    }

    const handleClosedByFirm = () => {
        dispatch(logOut(clientId, closedByFirmCallback))
        localStorage.removeItem('encodedClientId_' + clientId)
    };
    
    const closedByFirmCallback = () => {
        navigate(`${TAXPAYER.CLOSED}${clientId}`);
    }

    const handleOneHubNavigation = () => {
        dispatch(getOneHubRedirectUrl(clientId, (url: string) => {
            window.location.href = url;
        }))
    };

    const showHomeButton = () => {
        return isAccessedFromOneHub && uteSettings.uteEnabled && !isMobile();
    };

    return (<header className={`type-${type}`}>
        <div className="drawer-icon" onClick={() => setShowDrawer(true)}>
            {!fromLinkLock && <HamburgerIcon />}
        </div>
        { isMobile() && type === HeaderType.WITHSTEP ? <></> : <div className={"logo-container"}>
            <img src={companyLogoPath} alt="company-logo" />
        </div>}
        {type === HeaderType.WITHSTEP && <StepComponent />}
        <div className="d-flex justify-content-between align-items-center right-container">
            {showHomeButton() ? <div className="d-flex justify-content-between align-items-center" onClick={handleOneHubNavigation}>
                <HomeIcon color={type === HeaderType.BICOLOR ? "#000" : "#fff"}/>
                <span className="margin-left-5-px">Home</span>
                </div> : <></>
            }
        { isMobile() && type === HeaderType.WITHSTEP ? <></> : <div className="d-flex justify-content-between align-items-center right-container">
            {
                !isMobile() || fromLinkLock ? (
                    <>
                        {type !== HeaderType.BICOLOR && !hideContact && <>
                            <ContactPopup name={`${contactPerson?.firstName} ${contactPerson?.lastName}`} emailAddress={contactPerson?.emailAddress} mobileNo={contactPerson?.phone} />
                        </>
                        }
                        {!fromLinkLock && <UserDropdown isUteEnabled={uteSettings.uteEnabled} />}
                    </>
                ) : !!message ? <div onClick={() => setShowMessagePopup(true)}>
                    <MailIcon />
                </div> : <div/>
            }
        </div>}
        </div>
        <Drawer className="side-menu-drawer" show={showDrawer} setShow={setShowDrawer}>
            <>
                <div className="greet ellipsis" title={taxpayer.current ? taxpayer.name : spouse.name}>Hi {taxpayer.current ? taxpayer.name : spouse.name}!</div>
                <div onClick={() => navigate(TAXPAYER.CONTACT_INFORMATION + clientId)}><ChatIconMobile /><span className="margin-left-5-px"> Contact Person</span></div>
                <div onClick={() => navigate(TAXPAYER.MY_ACCOUNT + clientId)}><UserIcon /><span className="margin-left-10-px">My Account</span></div>
                <div onClick={handleLogout}> <LogOutIcon /><span className="margin-left-10-px">Log Out</span></div>
                <div className="bg-image">
                    <ClockCalenderBG />
                </div>
            </>
        </Drawer>
        <CpaMessageModal show={showMessagePopup} setShow={setShowMessagePopup} />
    </header>);
};

export default Header;