import React, { useEffect, memo } from "react";
import * as signalR from '@microsoft/signalr';
import { getWebSocketUrl, signalRConnectionInit, addGroup } from 'store/services/signalR-service';
import { ISignalRConnectionInfo } from "common/model/signal-r-connection-Info";
import { useAppDispatch } from "common/hooks/redux-hooks";
import { setStatusUpdateModel } from "store/slices/statusUpdateSlice";
import { NotificationType, SourceDocumentAIStatus } from "common/enum";
import { useNavigate } from "react-router";
import { DEFAULT, TAXPAYER } from "route/paths";
import { useClientId } from "common/hooks/client-hook";
import { getCategoryList, getDocumentRequestList } from "store/services/documentRequestList";
import { setSourceDocumentMappingDetails } from "store/slices/sourceDocumentMappingDetailsSlice";
import { logOut } from "store/services/otpStore";

interface SignalRProps {
    client_id: string;
}

interface ConnectionDetails {
    url: string,
    token: string
}

export interface INotificationMessage {
    gatherId: number;
    companyId: number;
    notificationType: NotificationType;
    data: any;
}

export interface ISourceDocumentNotificationMessage {
    clientId: string;
    notificationType: NotificationType;
    data: IDocumentFormTypeMappingData;
}
export interface IDocumentFormTypeMappingData {
    documentId: number;
    fileName: string;
    sourceDocumentMappingStatus: SourceDocumentAIStatus;
    gatherDocumentRequestId: number;
    errorMessage: string;
}

export const SignalRWebSocket: React.FC<SignalRProps> = ({ client_id }) => {
    var user = "";
    var group = "";
    var apiBaseUrl = "";
    var token  = "";
    const [clientId] = useClientId();

    useEffect(() => {
        getWebSocketUrl(clientId, handleWebSocketUrlResponse);
    }, []);

    var dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleWebSocketUrlResponse = (connectionDetails: ConnectionDetails) => {
        apiBaseUrl = connectionDetails.url;
        token = connectionDetails.token;
        user = client_id;
        group = client_id;
        signalRConnectionInit(user, connectionDetails.url, connectionDetails.token, initiateConnection);
    };

    const closedByFirmCallback = (encodedClientId: string) => {
        navigate(`${TAXPAYER.CLOSED}${client_id}`);
    }

    const handleClosedByFirm = () => {
        dispatch(logOut(clientId, closedByFirmCallback))
        localStorage.removeItem('encodedClientId_'+clientId)
    };

    const processStatusUpdated = (notificationMessage: INotificationMessage) => {
        let value:keyof typeof NotificationType = notificationMessage.notificationType;

        if (NotificationType[value] == NotificationType.ClosedByFirm) {
            handleClosedByFirm();
            return;
        }

        if (NotificationType[value] == NotificationType.GatherDeleted ) {
            handleGatherDeleted();
            return;
        }

        dispatch(setStatusUpdateModel({isStatusChanged: true, notificationType: notificationMessage.notificationType}));
    };

    const gatherDeletedCallback = (encodedClientId: string) => {
        navigate(`${DEFAULT}`);
    }

    const handleGatherDeleted = () => {
        dispatch(logOut(clientId, gatherDeletedCallback))
        localStorage.removeItem('encodedClientId_'+clientId)
    };

    const sourceDocumentMappingCompleted = (sourceDocumentNotificationMessage: ISourceDocumentNotificationMessage) => { 
        var dataformTypeData:IDocumentFormTypeMappingData = sourceDocumentNotificationMessage.data;
        dispatch(setSourceDocumentMappingDetails({
            sourceDocumentName: dataformTypeData.fileName,
            drlName: "",
            id: -1,
            sourceDocumentId: dataformTypeData.documentId,
            gatherdocumentRequestId: dataformTypeData.gatherDocumentRequestId
        }));
    };

    const clientInfoUpdated = (notificationMessage: INotificationMessage) => {
        dispatch(getDocumentRequestList(client_id));
        dispatch(getCategoryList(client_id));
    };

    const initiateConnection = (info: ISignalRConnectionInfo) => {
        info.accessToken = info.accessToken || info.accessKey;
        info.url = info.url || info.endpoint;
        const options = {
            accessTokenFactory: () => info.accessToken
        };
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(info.url, options)
            .configureLogging(signalR.LogLevel.Information)
            .build();
        connection.onclose(() => {
            startConnection(connection);
        });
        startConnection(connection);
    };

    const startConnection = (connection: any) => {
        connection.start()
            .then(function () {
                connection.on('ClientInfoUpdated', clientInfoUpdated);
                connection.on('DocumentStatusUpdated', processStatusUpdated);
                connection.on('SourceDocumentMappingCompleted', sourceDocumentMappingCompleted);
                addGroup(user, group, apiBaseUrl, token);
                connection.invoke('getConnectionId')
                    .then(function (connectionId: any) {
                    });
            })
            .catch(function (err: any) {
                console.error(err);
                setTimeout(startConnection, 5000, connection);
            });
    };
    return <div />;
}

export const MemoizedSignalRWebSocket = memo(SignalRWebSocket);