import React, { useEffect } from "react";
import ContactContent from "../../common/components/layout/ContactContent";
import MobileHeader from "../../common/components/layout/MobileHeader";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { requestHeaderInfo } from "store/services/coverPage";
import { useClientId } from "common/hooks/client-hook";
import { useNavigate } from "react-router-dom";
import { ErrorStatus } from "common/enum";
import { TAXPAYER } from "route/paths";
import { logOut } from "store/services/otpStore";

const ContactInformation = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const [clientId] =useClientId()

    useEffect(()=>{
        dispatch(requestHeaderInfo(clientId,false, () => {}, handleRequestError))
    },[])

    const contactPerson =useAppSelector((state:AppState)=>state.coverPageReducer?.contactPerson)

    const handleRequestError = (errorStatus: ErrorStatus) => {
        if(errorStatus === ErrorStatus.ClosedByFirm){
            handleClosedByFirm();
            return;
        }
    }

    const handleClosedByFirm = () => {
        dispatch(logOut(clientId, closedByFirmCallback))
        localStorage.removeItem('encodedClientId_' + clientId)
    };
    
    const closedByFirmCallback = () => {
        navigate(`${TAXPAYER.CLOSED}${clientId}`);
    }

    return <div>
        <MobileHeader />
        <ContactContent name={contactPerson.firstName+" "+contactPerson.lastName} mobileNo={contactPerson.phone} emailAddress={contactPerson.emailAddress} isMobile />
    </div>
};

export default ContactInformation;